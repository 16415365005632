@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './theme' as theme;

@mixin apply-theme($selector: '.theme-level-up') {
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    *,
    ::before,
    ::after {
      border-style: none;
    }
  }

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,500,0,0');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

  @include mat.typography-hierarchy(theme.$typography, $selector);

  #{$selector} {
    @include shared.helper-classes();
    @include theme.material-theme();
    @include theme.material-extended();
    @include theme.ngx-auth-firebaseui-extended();
    @include theme.drag-drop;
    @include theme.cdk-drag-preview;
    @include theme.misc;
    @include theme.intercom;
  }
}

@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './material-theme' as theme;
@use './typography' as typography;

@mixin material-extended() {
  @include typography.custom-typography();
  @include mat-chips-extended();
  @include mat-list-extended();
  @include mat-button-extended();
  @include mat-dialog-extended();
  @include mat-tab-extended();
  @include style-links-by-palette(theme.$primary);

  p {
    margin: 12px 0;
  }

  .toolbar-dialog {
    mat-dialog-container {
      padding: 0;
    }
  }

  .mat-sidenav-container {
    height: 100%;
    background-color: transparent;
  }

  .mat-tooltip {
    font-size: mat.m2-font-size(typography.$typography, caption) !important;
  }

  .multi-line-tooltip {
    white-space: pre-line;
  }

  .mat-progress-bar-buffer {
    background-color: #cccccc;
  }

  .mat-progress-bar-fill::after {
    background-color: #3ec26b;
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-mdc-button-base {
      min-height: 36px;
      height: inherit;
    }
    &.dense {
      font-size: 12px;
      line-height: 24px;
      padding: 0 8px;
      min-height: 30px;
    }
  }

  .mat-mdc-unelevated-button.mat-accent,
  .mat-mdc-raised-button.mat-accent,
  .mat-mdc-fab.mat-accent,
  .mat-mdc-mini-fab.mat-accent {
    color: #fff;
  }

  .mdc-dialog__title + .mdc-dialog__content,
  .mdc-dialog__header + .mdc-dialog__content {
    padding-top: 20px;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 25px;
  }

  .mat-mdc-form-field.compact {
    .mat-mdc-form-field-wrapper {
      height: 36px;
      padding-bottom: 0;
      .mat-mdc-form-field-underline {
        bottom: 0;
      }

      &.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 7px;
        padding-bottom: 0;
        min-height: 36px;
      }
    }

    .mat-mdc-form-field-infix {
      border-top: 0;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-21.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mat-mdc-form-field-infix {
        padding-top: 7px;
        padding-bottom: 0;
        min-height: 36px;
      }
    }

    .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex
      .mat-mdc-floating-label {
      font-size: 12px;
      top: 15px;
    }
  }

  .mat-mdc-form-field-hint-wrapper {
    color: rgba(0, 0, 0, 0.54);
    font-size: 10.5px;
  }

  .mdc-notched-outline > * {
    border-color: #ddd !important;
  }

  .mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines
    .mdc-list-item__end,
  .mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta
    .mdc-list-item__end {
    display: flex;
    align-self: center;
  }

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

/**
 * Colour anchor tags by the given material palette
 */
@mixin style-links-by-palette($color-palette) {
  a {
    text-decoration: none;
    &:link {
      color: mat.m2-get-color-from-palette($color-palette, 500);
    }
    &:visited {
      color: mat.m2-get-color-from-palette($color-palette, 700);
    }
    &:hover {
      color: mat.m2-get-color-from-palette($color-palette, 400);
    }
    &:active {
      color: mat.m2-get-color-from-palette($color-palette, 600);
    }
  }
}

/**
 * Extended classes for modifying the display of mat-chip components
 */
@mixin mat-chips-extended() {
  mat-chip-listbox {
    &.compact {
      .mat-mdc-chip-listbox-wrapper {
        margin: -4px 0;
        .mat-standard-chip {
          margin: 1px;
        }
      }
    }
  }
}

/**
 * Extended classes for modifying the display of mat-list components
 */
@mixin mat-list-extended() {
  mat-list {
    &.flush-list {
      .mat-list-item-content {
        padding: 0 !important;
      }
    }
    &.with-dividers {
      .mat-mdc-list-item {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
}

/**
 * Extended classes for modifying the display of mat-chip components
 */
@mixin mat-button-extended() {
  button {
    &.square {
      @include shared.border-radius(0);
    }
  }

  .mat-mdc-icon-button {
    @include shared.mat-icon-button-size(24px, 8px);
  }

  .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px !important;
  }
}

@mixin mat-tab-extended() {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    padding-left: shared.$mat-margin !important;
    padding-right: shared.$mat-margin !important;

    .mdc-tab__ripple {
      display: none;
    }

    &:hover {
      background: white !important;

      .mdc-tab__text-label {
        background: #f2eaff !important;
      }
    }

    .mdc-tab__text-label {
      padding: 8px 13px !important;
      border-radius: 8px !important;
    }

    .mdc-tab-indicator {
      display: none;
    }

    &.mdc-tab--active {
      .mdc-tab__text-label {
        background: #f2eaff !important;
        padding: 8px 12px;
        border-radius: 8px;
        font-weight: bold !important;
      }
    }
  }
}

@mixin mat-dialog-extended() {
  .toolbar-dialog {
    mat-dialog-container {
      padding: 0;
    }
  }
}

@mixin mat-list-item-height($height) {
  height: $height;
  .mat-list-item-content {
    height: $height;
  }
}

@use '@angular/material' as mat;

$montserrat-family: 'Montserrat', 'Helvetica Neue', sans-serif;

$typography: mat.m2-define-typography-config(
  $font-family: $montserrat-family,
  $headline-1: mat.m2-define-typography-level(112px, 1.1em, 600),
  $headline-2: mat.m2-define-typography-level(56px, 1.1em, 600),
  $headline-3: mat.m2-define-typography-level(48px, 1.1em, 600),
  $headline-4: mat.m2-define-typography-level(34px, 1.1em, 600),
  $headline-5: mat.m2-define-typography-level(28px, 1.6em, 600),
  $headline-6: mat.m2-define-typography-level(18px, 1.6em, 600),
  $subtitle-1: mat.m2-define-typography-level(14px, 1.6em, 600),
  $subtitle-2: mat.m2-define-typography-level(14px, 1.6em, 400),
  $body-1: mat.m2-define-typography-level(14px, 1.6em, 400),
  $body-2: mat.m2-define-typography-level(14px, 1.6em, 400),
  $caption: mat.m2-define-typography-level(12px, 1.6em, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500),
);

@mixin page-header {
  margin: 80px 0 0 !important;
}

@mixin page-title {
  margin: 50px 0 8px !important;
}

@mixin page-subheader {
  margin: 0 !important;
}

@mixin white-page-description {
  h2,
  p {
    color: #fff;
  }
  h2 {
    margin: 0;
  }
  a {
    text-decoration: underline;
    &:link {
      color: rgba(#fff, 0.5);
    }
    &:visited {
      color: rgba(#fff, 0.7);
    }
    &:hover {
      color: rgba(#fff, 0.4);
    }
    &:active {
      color: rgba(#fff, 0.6);
    }
  }
}

@mixin custom-typography() {
  .mat-caption {
    opacity: 0.75;
  }
}

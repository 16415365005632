@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,500,0,0");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/**
 * Apply a consistent border radius to this element
 * @mixin
 * @section Layout Mixins
 * @example
 *  @include border-radius(10px);
 */
.pt-connected-dialog-container {
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  background: white;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
@media (forced-colors: active) {
  .pt-connected-dialog-container {
    outline: solid 1px;
  }
}

.pt-dialog-content {
  display: block;
  margin: 0;
  padding: 0 24px;
  max-height: 65vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.pt-dialog-title {
  display: block;
}

.pt-dialog-actions {
  padding: 8px 24px;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  justify-content: flex-end;
}
.pt-dialog-actions[align=end] {
  justify-content: flex-end;
}
.pt-dialog-actions[align=center] {
  justify-content: center;
}
.pt-dialog-actions .mat-mdc-button + .mat-button,
.pt-dialog-actions .mat-mdc-raised-button + .mat-mdc-raised-button,
.pt-dialog-actions .mat-button + .mat-mdc-raised-button,
.pt-dialog-actions .mat-mdc-raised-button + .mat-button {
  margin-left: 8px;
}
[dir=rtl] .pt-dialog-actions .mat-mdc-button + .mat-button,
[dir=rtl] .pt-dialog-actions .mat-mdc-raised-button + .mat-mdc-raised-button,
[dir=rtl] .pt-dialog-actions .mat-button + .mat-mdc-raised-button,
[dir=rtl] .pt-dialog-actions .mat-mdc-raised-button + .mat-button {
  margin-left: 0;
  margin-right: 8px;
}

/**
 * Apply a grayscale to this element
 * @mixin
 * @example
 *  @extend grayscale(100%);
 */
/**
 * Used for padding and margins
 * @nuclide mat-margin
 * @section Layout > Margin & Padding
 */
/**
 * Apply a consistent padding to this element
 * @mixin
 * @section Layout Mixins
 * @example
 *  @include layout-padding;
 */
/**
 * Apply a consistent margin to this element
 * @mixin
 * @section Layout Mixins
 * @example
 *  @include layout-margin;
 */
/* Customise website's scrollbar like Mac OS
  Doesn't support Firefox and IE */
.custom-input-default {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #d3d0cb;
  padding: 2px 6px;
}
.custom-input-default::placeholder {
  color: #5f6368;
}
.custom-input-default:focus {
  outline-color: #d3d0cb;
}

/**
 * Colour anchor tags by the given material palette
 */
/**
 * Extended classes for modifying the display of mat-chip components
 */
/**
 * Extended classes for modifying the display of mat-list components
 */
/**
 * Extended classes for modifying the display of mat-chip components
 */
@media print {
  mat-sidenav,
  button,
  snack-bar-container {
    display: none !important;
  }
  mat-sidenav-content {
    margin-left: 0 !important;
  }
  .mat-drawer-container,
  .mat-drawer-content {
    overflow: visible !important;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  *,
  ::before,
  ::after {
    border-style: none;
  }
}
.mat-h1,
.mat-headline-5,
.theme-level-up .mat-h1,
.theme-level-up .mat-headline-5,
.theme-level-up h1 {
  font: 600 28px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.theme-level-up .mat-h2,
.theme-level-up .mat-headline-6,
.theme-level-up h2 {
  font: 600 18px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.theme-level-up .mat-h3,
.theme-level-up .mat-subtitle-1,
.theme-level-up h3 {
  font: 600 14px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.theme-level-up .mat-h4,
.theme-level-up .mat-body-1,
.theme-level-up h4 {
  font: 400 14px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.theme-level-up .mat-h5,
.theme-level-up h5 {
  font: 400 calc(14px * 0.83) / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.theme-level-up .mat-h6,
.theme-level-up h6 {
  font: 400 calc(14px * 0.67) / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.theme-level-up .mat-body-strong,
.theme-level-up .mat-subtitle-2 {
  font: 400 14px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.theme-level-up .mat-body,
.theme-level-up .mat-body-2,
.theme-level-up {
  font: 400 14px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.theme-level-up .mat-body p,
.theme-level-up .mat-body-2 p,
.theme-level-up p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.theme-level-up .mat-small,
.theme-level-up .mat-caption {
  font: 400 12px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-headline-1,
.theme-level-up .mat-headline-1 {
  font: 600 112px / 1.1em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.theme-level-up .mat-headline-2 {
  font: 600 56px / 1.1em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.theme-level-up .mat-headline-3 {
  font: 600 48px / 1.1em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.theme-level-up .mat-headline-4 {
  font: 600 34px / 1.1em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.theme-level-up .layout-padding {
  padding: 16px;
}
.theme-level-up .layout-margin {
  margin: 16px;
}
.theme-level-up .placeholder {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .tooltip-multiline {
  white-space: pre;
}
.theme-level-up {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up {
  --mat-option-selected-state-label-text-color: #7b42f5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-level-up .mat-accent {
  --mat-option-selected-state-label-text-color: #10a5ce;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-level-up .mat-warn {
  --mat-option-selected-state-label-text-color: #d7561e;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-level-up {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up {
  --mat-full-pseudo-checkbox-selected-icon-color: #10a5ce;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.theme-level-up {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #10a5ce;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-level-up .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #7b42f5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.theme-level-up .mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #7b42f5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-level-up .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #10a5ce;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.theme-level-up .mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #10a5ce;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-level-up .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #d7561e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.theme-level-up .mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d7561e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-level-up {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-option-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-option-label-text-line-height: 1.6em;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}
.theme-level-up {
  --mat-optgroup-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-optgroup-label-text-line-height: 1.6em;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}
.theme-level-up {
  --mdc-elevated-card-container-shape: 4px;
}
.theme-level-up {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
.theme-level-up {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}
.theme-level-up {
  --mat-card-title-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-card-title-text-line-height: 1.6em;
  --mat-card-title-text-size: 18px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 600;
  --mat-card-subtitle-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-card-subtitle-text-line-height: 1.6em;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 400;
}
.theme-level-up {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}
.theme-level-up .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #7b42f5;
  --mdc-linear-progress-track-color: rgba(123, 66, 245, 0.25);
}
.theme-level-up .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #10a5ce;
  --mdc-linear-progress-track-color: rgba(16, 165, 206, 0.25);
}
.theme-level-up .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #d7561e;
  --mdc-linear-progress-track-color: rgba(215, 86, 30, 0.25);
}
.theme-level-up {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}
.theme-level-up {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.theme-level-up {
  --mdc-plain-tooltip-supporting-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}
.theme-level-up {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
.theme-level-up {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
.theme-level-up {
  --mdc-filled-text-field-caret-color: #7b42f5;
  --mdc-filled-text-field-focus-active-indicator-color: #7b42f5;
  --mdc-filled-text-field-focus-label-text-color: rgba(123, 66, 245, 0.87);
  --mdc-filled-text-field-container-color: rgb(244.8, 244.8, 244.8);
  --mdc-filled-text-field-disabled-container-color: rgb(249.9, 249.9, 249.9);
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #d7561e;
  --mdc-filled-text-field-error-focus-label-text-color: #d7561e;
  --mdc-filled-text-field-error-label-text-color: #d7561e;
  --mdc-filled-text-field-error-caret-color: #d7561e;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #d7561e;
  --mdc-filled-text-field-error-focus-active-indicator-color: #d7561e;
  --mdc-filled-text-field-error-hover-active-indicator-color: #d7561e;
}
.theme-level-up {
  --mdc-outlined-text-field-caret-color: #7b42f5;
  --mdc-outlined-text-field-focus-outline-color: #7b42f5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(123, 66, 245, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #d7561e;
  --mdc-outlined-text-field-error-focus-label-text-color: #d7561e;
  --mdc-outlined-text-field-error-label-text-color: #d7561e;
  --mdc-outlined-text-field-error-hover-label-text-color: #d7561e;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #d7561e;
  --mdc-outlined-text-field-error-hover-outline-color: #d7561e;
  --mdc-outlined-text-field-error-outline-color: #d7561e;
}
.theme-level-up {
  --mat-form-field-focus-select-arrow-color: rgba(123, 66, 245, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #d7561e;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}
.theme-level-up .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #10a5ce;
  --mdc-filled-text-field-focus-active-indicator-color: #10a5ce;
  --mdc-filled-text-field-focus-label-text-color: rgba(16, 165, 206, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #10a5ce;
  --mdc-outlined-text-field-focus-outline-color: #10a5ce;
  --mdc-outlined-text-field-focus-label-text-color: rgba(16, 165, 206, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(16, 165, 206, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #d7561e;
  --mdc-filled-text-field-focus-active-indicator-color: #d7561e;
  --mdc-filled-text-field-focus-label-text-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #d7561e;
  --mdc-outlined-text-field-focus-outline-color: #d7561e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}
.theme-level-up {
  --mdc-filled-text-field-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
}
.theme-level-up {
  --mdc-outlined-text-field-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
}
.theme-level-up {
  --mat-form-field-container-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-form-field-container-text-line-height: 1.6em;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-form-field-subscript-text-line-height: 1.6em;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}
.theme-level-up {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(123, 66, 245, 0.87);
  --mat-select-invalid-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(16, 165, 206, 0.87);
  --mat-select-invalid-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(215, 86, 30, 0.87);
  --mat-select-invalid-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up {
  --mat-select-arrow-transform: translateY(-8px);
}
.theme-level-up {
  --mat-select-trigger-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-select-trigger-text-line-height: 1.6em;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}
.theme-level-up {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-autocomplete-background-color: white;
}
.theme-level-up {
  --mdc-dialog-container-shape: 4px;
}
.theme-level-up {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}
.theme-level-up {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-level-up {
  --mdc-dialog-subhead-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-dialog-subhead-line-height: 1.6em;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-dialog-supporting-text-line-height: 1.6em;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}
.theme-level-up .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.theme-level-up .mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-level-up .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.theme-level-up .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #7b42f5;
  --mdc-chip-elevated-selected-container-color: #7b42f5;
  --mdc-chip-elevated-disabled-container-color: #7b42f5;
  --mdc-chip-flat-disabled-selected-container-color: #7b42f5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #10a5ce;
  --mdc-chip-elevated-selected-container-color: #10a5ce;
  --mdc-chip-elevated-disabled-container-color: #10a5ce;
  --mdc-chip-flat-disabled-selected-container-color: #10a5ce;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #d7561e;
  --mdc-chip-elevated-selected-container-color: #d7561e;
  --mdc-chip-elevated-disabled-container-color: #d7561e;
  --mdc-chip-flat-disabled-selected-container-color: #d7561e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-level-up .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-level-up .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.theme-level-up .mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-chip-label-text-line-height: 1.6em;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}
.theme-level-up {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
.theme-level-up .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.theme-level-up {
  --mdc-switch-selected-focus-state-layer-color: #733cf4;
  --mdc-switch-selected-handle-color: #733cf4;
  --mdc-switch-selected-hover-state-layer-color: #733cf4;
  --mdc-switch-selected-pressed-state-layer-color: #733cf4;
  --mdc-switch-selected-focus-handle-color: #4b1dee;
  --mdc-switch-selected-hover-handle-color: #4b1dee;
  --mdc-switch-selected-pressed-handle-color: #4b1dee;
  --mdc-switch-selected-focus-track-color: #a37bf8;
  --mdc-switch-selected-hover-track-color: #a37bf8;
  --mdc-switch-selected-pressed-track-color: #a37bf8;
  --mdc-switch-selected-track-color: #a37bf8;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #0e9dc9;
  --mdc-switch-selected-handle-color: #0e9dc9;
  --mdc-switch-selected-hover-state-layer-color: #0e9dc9;
  --mdc-switch-selected-pressed-state-layer-color: #0e9dc9;
  --mdc-switch-selected-focus-handle-color: #0579b0;
  --mdc-switch-selected-hover-handle-color: #0579b0;
  --mdc-switch-selected-pressed-handle-color: #0579b0;
  --mdc-switch-selected-focus-track-color: #58c0dd;
  --mdc-switch-selected-hover-track-color: #58c0dd;
  --mdc-switch-selected-pressed-track-color: #58c0dd;
  --mdc-switch-selected-track-color: #58c0dd;
}
.theme-level-up .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #d34f1a;
  --mdc-switch-selected-handle-color: #d34f1a;
  --mdc-switch-selected-hover-state-layer-color: #d34f1a;
  --mdc-switch-selected-pressed-state-layer-color: #d34f1a;
  --mdc-switch-selected-focus-handle-color: #be2b0a;
  --mdc-switch-selected-hover-handle-color: #be2b0a;
  --mdc-switch-selected-pressed-handle-color: #be2b0a;
  --mdc-switch-selected-focus-track-color: #e38962;
  --mdc-switch-selected-hover-track-color: #e38962;
  --mdc-switch-selected-pressed-track-color: #e38962;
  --mdc-switch-selected-track-color: #e38962;
}
.theme-level-up {
  --mdc-switch-state-layer-size: 40px;
}
.theme-level-up .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-switch-label-text-line-height: 1.6em;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}
.theme-level-up {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.theme-level-up .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b42f5;
  --mdc-radio-selected-hover-icon-color: #7b42f5;
  --mdc-radio-selected-icon-color: #7b42f5;
  --mdc-radio-selected-pressed-icon-color: #7b42f5;
}
.theme-level-up .mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #7b42f5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #10a5ce;
  --mdc-radio-selected-hover-icon-color: #10a5ce;
  --mdc-radio-selected-icon-color: #10a5ce;
  --mdc-radio-selected-pressed-icon-color: #10a5ce;
}
.theme-level-up .mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #10a5ce;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d7561e;
  --mdc-radio-selected-hover-icon-color: #d7561e;
  --mdc-radio-selected-icon-color: #d7561e;
  --mdc-radio-selected-pressed-icon-color: #d7561e;
}
.theme-level-up .mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #d7561e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up {
  --mdc-radio-state-layer-size: 40px;
}
.theme-level-up {
  --mat-radio-touch-target-display: block;
}
.theme-level-up {
  --mat-radio-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-radio-label-text-line-height: 1.6em;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}
.theme-level-up {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}
.theme-level-up {
  --mdc-slider-handle-color: #7b42f5;
  --mdc-slider-focus-handle-color: #7b42f5;
  --mdc-slider-hover-handle-color: #7b42f5;
  --mdc-slider-active-track-color: #7b42f5;
  --mdc-slider-inactive-track-color: #7b42f5;
  --mdc-slider-with-tick-marks-inactive-container-color: #7b42f5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
}
.theme-level-up {
  --mat-slider-ripple-color: #7b42f5;
  --mat-slider-hover-state-layer-color: rgba(123, 66, 245, 0.05);
  --mat-slider-focus-state-layer-color: rgba(123, 66, 245, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
.theme-level-up .mat-accent {
  --mdc-slider-handle-color: #10a5ce;
  --mdc-slider-focus-handle-color: #10a5ce;
  --mdc-slider-hover-handle-color: #10a5ce;
  --mdc-slider-active-track-color: #10a5ce;
  --mdc-slider-inactive-track-color: #10a5ce;
  --mdc-slider-with-tick-marks-inactive-container-color: #10a5ce;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-accent {
  --mat-slider-ripple-color: #10a5ce;
  --mat-slider-hover-state-layer-color: rgba(16, 165, 206, 0.05);
  --mat-slider-focus-state-layer-color: rgba(16, 165, 206, 0.2);
}
.theme-level-up .mat-warn {
  --mdc-slider-handle-color: #d7561e;
  --mdc-slider-focus-handle-color: #d7561e;
  --mdc-slider-hover-handle-color: #d7561e;
  --mdc-slider-active-track-color: #d7561e;
  --mdc-slider-inactive-track-color: #d7561e;
  --mdc-slider-with-tick-marks-inactive-container-color: #d7561e;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-level-up .mat-warn {
  --mat-slider-ripple-color: #d7561e;
  --mat-slider-hover-state-layer-color: rgba(215, 86, 30, 0.05);
  --mat-slider-focus-state-layer-color: rgba(215, 86, 30, 0.2);
}
.theme-level-up {
  --mdc-slider-label-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 1.6em;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 400;
}
.theme-level-up {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-menu-item-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1.6em;
  --mat-menu-item-label-text-weight: 400;
}
.theme-level-up {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
.theme-level-up {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}
.theme-level-up {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.theme-level-up .mdc-list-item__start,
.theme-level-up .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b42f5;
  --mdc-radio-selected-hover-icon-color: #7b42f5;
  --mdc-radio-selected-icon-color: #7b42f5;
  --mdc-radio-selected-pressed-icon-color: #7b42f5;
}
.theme-level-up .mat-accent .mdc-list-item__start,
.theme-level-up .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #10a5ce;
  --mdc-radio-selected-hover-icon-color: #10a5ce;
  --mdc-radio-selected-icon-color: #10a5ce;
  --mdc-radio-selected-pressed-icon-color: #10a5ce;
}
.theme-level-up .mat-warn .mdc-list-item__start,
.theme-level-up .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d7561e;
  --mdc-radio-selected-hover-icon-color: #d7561e;
  --mdc-radio-selected-icon-color: #d7561e;
  --mdc-radio-selected-pressed-icon-color: #d7561e;
}
.theme-level-up .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #7b42f5;
  --mdc-checkbox-selected-hover-icon-color: #7b42f5;
  --mdc-checkbox-selected-icon-color: #7b42f5;
  --mdc-checkbox-selected-pressed-icon-color: #7b42f5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-hover-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-pressed-state-layer-color: #7b42f5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-level-up .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #10a5ce;
  --mdc-checkbox-selected-hover-icon-color: #10a5ce;
  --mdc-checkbox-selected-icon-color: #10a5ce;
  --mdc-checkbox-selected-pressed-icon-color: #10a5ce;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-hover-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-pressed-state-layer-color: #10a5ce;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-level-up .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d7561e;
  --mdc-checkbox-selected-hover-icon-color: #d7561e;
  --mdc-checkbox-selected-icon-color: #d7561e;
  --mdc-checkbox-selected-pressed-icon-color: #d7561e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d7561e;
  --mdc-checkbox-selected-hover-state-layer-color: #d7561e;
  --mdc-checkbox-selected-pressed-state-layer-color: #d7561e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-level-up .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-level-up .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.theme-level-up .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-level-up .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #7b42f5;
}
.theme-level-up .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-level-up .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-level-up .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-level-up {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
.theme-level-up {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}
.theme-level-up .mdc-list-item__start,
.theme-level-up .mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}
.theme-level-up .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .theme-level-up .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .theme-level-up .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.theme-level-up .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .theme-level-up .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .theme-level-up .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}
.theme-level-up {
  --mdc-list-list-item-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-list-list-item-label-text-line-height: 1.6em;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 1.6em;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.6em;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.theme-level-up .mdc-list-group__subheader {
  font: 600 14px / 1.6em "Montserrat", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.theme-level-up {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}
.theme-level-up {
  --mat-paginator-container-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-paginator-container-text-line-height: 1.6em;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}
.theme-level-up {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.theme-level-up {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
.theme-level-up {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.theme-level-up .mat-mdc-tab-group,
.theme-level-up .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #7b42f5;
}
.theme-level-up .mat-mdc-tab-group,
.theme-level-up .mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #7b42f5;
  --mat-tab-header-active-ripple-color: #7b42f5;
  --mat-tab-header-inactive-ripple-color: #7b42f5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #7b42f5;
  --mat-tab-header-active-hover-label-text-color: #7b42f5;
  --mat-tab-header-active-focus-indicator-color: #7b42f5;
  --mat-tab-header-active-hover-indicator-color: #7b42f5;
}
.theme-level-up .mat-mdc-tab-group.mat-accent,
.theme-level-up .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #10a5ce;
}
.theme-level-up .mat-mdc-tab-group.mat-accent,
.theme-level-up .mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #10a5ce;
  --mat-tab-header-active-ripple-color: #10a5ce;
  --mat-tab-header-inactive-ripple-color: #10a5ce;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #10a5ce;
  --mat-tab-header-active-hover-label-text-color: #10a5ce;
  --mat-tab-header-active-focus-indicator-color: #10a5ce;
  --mat-tab-header-active-hover-indicator-color: #10a5ce;
}
.theme-level-up .mat-mdc-tab-group.mat-warn,
.theme-level-up .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #d7561e;
}
.theme-level-up .mat-mdc-tab-group.mat-warn,
.theme-level-up .mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #d7561e;
  --mat-tab-header-active-ripple-color: #d7561e;
  --mat-tab-header-inactive-ripple-color: #d7561e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d7561e;
  --mat-tab-header-active-hover-label-text-color: #d7561e;
  --mat-tab-header-active-focus-indicator-color: #d7561e;
  --mat-tab-header-active-hover-indicator-color: #d7561e;
}
.theme-level-up .mat-mdc-tab-group.mat-background-primary,
.theme-level-up .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #7b42f5;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-level-up .mat-mdc-tab-group.mat-background-accent,
.theme-level-up .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #10a5ce;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-tab-group.mat-background-warn,
.theme-level-up .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #d7561e;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-level-up .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.theme-level-up .mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}
.theme-level-up {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
.theme-level-up {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #10a5ce;
  --mdc-checkbox-selected-hover-icon-color: #10a5ce;
  --mdc-checkbox-selected-icon-color: #10a5ce;
  --mdc-checkbox-selected-pressed-icon-color: #10a5ce;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-hover-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-pressed-state-layer-color: #10a5ce;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-level-up {
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #7b42f5;
  --mdc-checkbox-selected-hover-icon-color: #7b42f5;
  --mdc-checkbox-selected-icon-color: #7b42f5;
  --mdc-checkbox-selected-pressed-icon-color: #7b42f5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-hover-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-pressed-state-layer-color: #7b42f5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-level-up .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d7561e;
  --mdc-checkbox-selected-hover-icon-color: #d7561e;
  --mdc-checkbox-selected-icon-color: #d7561e;
  --mdc-checkbox-selected-pressed-icon-color: #d7561e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d7561e;
  --mdc-checkbox-selected-hover-state-layer-color: #d7561e;
  --mdc-checkbox-selected-pressed-state-layer-color: #d7561e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-level-up {
  --mdc-checkbox-state-layer-size: 40px;
}
.theme-level-up {
  --mat-checkbox-touch-target-display: block;
}
.theme-level-up {
  --mat-checkbox-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-checkbox-label-text-line-height: 1.6em;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}
.theme-level-up {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
.theme-level-up {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
.theme-level-up {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
.theme-level-up {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
.theme-level-up {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
.theme-level-up {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
.theme-level-up {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}
.theme-level-up {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.theme-level-up {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.theme-level-up {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.theme-level-up {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.theme-level-up .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #7b42f5;
}
.theme-level-up .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #7b42f5;
  --mat-text-button-ripple-color: rgba(123, 66, 245, 0.1);
}
.theme-level-up .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #10a5ce;
}
.theme-level-up .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #10a5ce;
  --mat-text-button-ripple-color: rgba(16, 165, 206, 0.1);
}
.theme-level-up .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #d7561e;
}
.theme-level-up .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #d7561e;
  --mat-text-button-ripple-color: rgba(215, 86, 30, 0.1);
}
.theme-level-up .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #7b42f5;
  --mdc-filled-button-label-text-color: white;
}
.theme-level-up .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #10a5ce;
  --mdc-filled-button-label-text-color: black;
}
.theme-level-up .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #d7561e;
  --mdc-filled-button-label-text-color: white;
}
.theme-level-up .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #7b42f5;
  --mdc-protected-button-label-text-color: white;
}
.theme-level-up .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #10a5ce;
  --mdc-protected-button-label-text-color: black;
}
.theme-level-up .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #d7561e;
  --mdc-protected-button-label-text-color: white;
}
.theme-level-up .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #7b42f5;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #7b42f5;
  --mat-outlined-button-ripple-color: rgba(123, 66, 245, 0.1);
}
.theme-level-up .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #10a5ce;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #10a5ce;
  --mat-outlined-button-ripple-color: rgba(16, 165, 206, 0.1);
}
.theme-level-up .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #d7561e;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #d7561e;
  --mat-outlined-button-ripple-color: rgba(215, 86, 30, 0.1);
}
.theme-level-up {
  --mdc-text-button-container-height: 36px;
}
.theme-level-up {
  --mdc-filled-button-container-height: 36px;
}
.theme-level-up {
  --mdc-protected-button-container-height: 36px;
}
.theme-level-up {
  --mdc-outlined-button-container-height: 36px;
}
.theme-level-up {
  --mat-text-button-touch-target-display: block;
}
.theme-level-up {
  --mat-filled-button-touch-target-display: block;
}
.theme-level-up {
  --mat-protected-button-touch-target-display: block;
}
.theme-level-up {
  --mat-outlined-button-touch-target-display: block;
}
.theme-level-up {
  --mdc-text-button-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
.theme-level-up {
  --mdc-filled-button-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
.theme-level-up {
  --mdc-protected-button-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
.theme-level-up {
  --mdc-outlined-button-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
.theme-level-up {
  --mdc-icon-button-icon-size: 24px;
}
.theme-level-up {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up {
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
.theme-level-up .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #7b42f5;
}
.theme-level-up .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #7b42f5;
  --mat-icon-button-ripple-color: rgba(123, 66, 245, 0.1);
}
.theme-level-up .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #10a5ce;
}
.theme-level-up .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #10a5ce;
  --mat-icon-button-ripple-color: rgba(16, 165, 206, 0.1);
}
.theme-level-up .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #d7561e;
}
.theme-level-up .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #d7561e;
  --mat-icon-button-ripple-color: rgba(215, 86, 30, 0.1);
}
.theme-level-up {
  --mat-icon-button-touch-target-display: block;
}
.theme-level-up .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}
.theme-level-up {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mdc-fab-container-color: white;
}
.theme-level-up {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up {
  --mdc-fab-small-container-color: white;
}
.theme-level-up {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #7b42f5;
}
.theme-level-up .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #10a5ce;
}
.theme-level-up .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #d7561e;
}
.theme-level-up .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #7b42f5;
}
.theme-level-up .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #10a5ce;
}
.theme-level-up .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #d7561e;
}
.theme-level-up .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up {
  --mat-fab-touch-target-display: block;
}
.theme-level-up {
  --mat-fab-small-touch-target-display: block;
}
.theme-level-up {
  --mdc-extended-fab-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}
.theme-level-up {
  --mdc-snackbar-container-shape: 4px;
}
.theme-level-up {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.theme-level-up {
  --mat-snack-bar-button-color: #d7c6fc;
}
.theme-level-up {
  --mdc-snackbar-supporting-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 1.6em;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}
.theme-level-up {
  --mat-table-row-item-outline-width: 1px;
}
.theme-level-up {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}
.theme-level-up {
  --mat-table-header-headline-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-table-header-headline-line-height: 1.6em;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-table-row-item-label-text-line-height: 1.6em;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-table-footer-supporting-text-line-height: 1.6em;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}
.theme-level-up {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}
.theme-level-up {
  --mdc-circular-progress-active-indicator-color: #7b42f5;
}
.theme-level-up .mat-accent {
  --mdc-circular-progress-active-indicator-color: #10a5ce;
}
.theme-level-up .mat-warn {
  --mdc-circular-progress-active-indicator-color: #d7561e;
}
.theme-level-up {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}
.theme-level-up {
  --mat-badge-background-color: #7b42f5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}
.theme-level-up .mat-badge-accent {
  --mat-badge-background-color: #10a5ce;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-badge-warn {
  --mat-badge-background-color: #d7561e;
  --mat-badge-text-color: white;
}
.theme-level-up {
  --mat-badge-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}
.theme-level-up {
  --mat-bottom-sheet-container-shape: 4px;
}
.theme-level-up {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}
.theme-level-up {
  --mat-bottom-sheet-container-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 1.6em;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}
.theme-level-up {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
.theme-level-up {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}
.theme-level-up {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
}
.theme-level-up {
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: rgb(224.4, 224.4, 224.4);
}
.theme-level-up {
  --mat-standard-button-toggle-height: 48px;
}
.theme-level-up {
  --mat-legacy-button-toggle-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 1.6em;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
}
.theme-level-up {
  --mat-standard-button-toggle-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 1.6em;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}
.theme-level-up {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #7b42f5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(123, 66, 245, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(123, 66, 245, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(123, 66, 245, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #7b42f5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(123, 66, 245, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #10a5ce;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(16, 165, 206, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(16, 165, 206, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(16, 165, 206, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(16, 165, 206, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.theme-level-up .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #d7561e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(215, 86, 30, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(215, 86, 30, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(215, 86, 30, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(215, 86, 30, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.theme-level-up .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #10a5ce;
}
.theme-level-up .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #d7561e;
}
.theme-level-up .mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.theme-level-up .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.theme-level-up {
  --mat-datepicker-calendar-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}
.theme-level-up {
  --mat-divider-width: 1px;
}
.theme-level-up {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}
.theme-level-up {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}
.theme-level-up {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}
.theme-level-up {
  --mat-expansion-header-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-expansion-container-text-line-height: 1.6em;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}
.theme-level-up {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}
.theme-level-up {
  --mat-icon-color: inherit;
}
.theme-level-up .mat-icon.mat-primary {
  --mat-icon-color: #7b42f5;
}
.theme-level-up .mat-icon.mat-accent {
  --mat-icon-color: #10a5ce;
}
.theme-level-up .mat-icon.mat-warn {
  --mat-icon-color: #d7561e;
}
.theme-level-up {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}
.theme-level-up {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}
.theme-level-up {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #7b42f5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #7b42f5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #7b42f5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #d7561e;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #d7561e;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.theme-level-up .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #10a5ce;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #10a5ce;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #10a5ce;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #d7561e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #d7561e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #d7561e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-level-up {
  --mat-stepper-header-height: 72px;
}
.theme-level-up {
  --mat-stepper-container-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-stepper-header-label-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}
.theme-level-up {
  --mat-sort-arrow-color: rgb(117.3, 117.3, 117.3);
}
.theme-level-up {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #7b42f5;
  --mat-toolbar-container-text-color: white;
}
.theme-level-up .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #10a5ce;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #d7561e;
  --mat-toolbar-container-text-color: white;
}
.theme-level-up {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}
.theme-level-up {
  --mat-toolbar-title-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-toolbar-title-text-line-height: 1.6em;
  --mat-toolbar-title-text-size: 18px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 600;
}
.theme-level-up {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up {
  --mat-tree-node-min-height: 48px;
}
.theme-level-up {
  --mat-tree-node-text-font: Montserrat, Helvetica Neue, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}
.theme-level-up {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up {
  --mat-timepicker-container-background-color: white;
}
.theme-level-up .dark-theme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme {
  --mat-option-selected-state-label-text-color: #7b42f5;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-level-up .dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #10a5ce;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-level-up .dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #d7561e;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-level-up .dark-theme {
  --mat-optgroup-label-text-color: white;
}
.theme-level-up .dark-theme {
  --mat-full-pseudo-checkbox-selected-icon-color: #10a5ce;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.theme-level-up .dark-theme {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #10a5ce;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-level-up .dark-theme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #7b42f5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.theme-level-up .dark-theme .mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #7b42f5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-level-up .dark-theme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #10a5ce;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.theme-level-up .dark-theme .mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #10a5ce;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-level-up .dark-theme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #d7561e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.theme-level-up .dark-theme .mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d7561e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-level-up .dark-theme {
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-elevated-card-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
.theme-level-up .dark-theme {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.theme-level-up .dark-theme {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}
.theme-level-up .dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #7b42f5;
  --mdc-linear-progress-track-color: rgba(123, 66, 245, 0.25);
}
.theme-level-up .dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #10a5ce;
  --mdc-linear-progress-track-color: rgba(16, 165, 206, 0.25);
}
.theme-level-up .dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #d7561e;
  --mdc-linear-progress-track-color: rgba(215, 86, 30, 0.25);
}
.theme-level-up .dark-theme {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}
.theme-level-up .dark-theme {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.theme-level-up .dark-theme {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mdc-filled-text-field-caret-color: #7b42f5;
  --mdc-filled-text-field-focus-active-indicator-color: #7b42f5;
  --mdc-filled-text-field-focus-label-text-color: rgba(123, 66, 245, 0.87);
  --mdc-filled-text-field-container-color: rgb(73.56, 73.56, 73.56);
  --mdc-filled-text-field-disabled-container-color: rgb(69.78, 69.78, 69.78);
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #d7561e;
  --mdc-filled-text-field-error-focus-label-text-color: #d7561e;
  --mdc-filled-text-field-error-label-text-color: #d7561e;
  --mdc-filled-text-field-error-caret-color: #d7561e;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #d7561e;
  --mdc-filled-text-field-error-focus-active-indicator-color: #d7561e;
  --mdc-filled-text-field-error-hover-active-indicator-color: #d7561e;
}
.theme-level-up .dark-theme {
  --mdc-outlined-text-field-caret-color: #7b42f5;
  --mdc-outlined-text-field-focus-outline-color: #7b42f5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(123, 66, 245, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #d7561e;
  --mdc-outlined-text-field-error-focus-label-text-color: #d7561e;
  --mdc-outlined-text-field-error-label-text-color: #d7561e;
  --mdc-outlined-text-field-error-hover-label-text-color: #d7561e;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #d7561e;
  --mdc-outlined-text-field-error-hover-outline-color: #d7561e;
  --mdc-outlined-text-field-error-outline-color: #d7561e;
}
.theme-level-up .dark-theme {
  --mat-form-field-focus-select-arrow-color: rgba(123, 66, 245, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #d7561e;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #10a5ce;
  --mdc-filled-text-field-focus-active-indicator-color: #10a5ce;
  --mdc-filled-text-field-focus-label-text-color: rgba(16, 165, 206, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #10a5ce;
  --mdc-outlined-text-field-focus-outline-color: #10a5ce;
  --mdc-outlined-text-field-focus-label-text-color: rgba(16, 165, 206, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(16, 165, 206, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #d7561e;
  --mdc-filled-text-field-focus-active-indicator-color: #d7561e;
  --mdc-filled-text-field-focus-label-text-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #d7561e;
  --mdc-outlined-text-field-focus-outline-color: #d7561e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .dark-theme {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(123, 66, 245, 0.87);
  --mat-select-invalid-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(16, 165, 206, 0.87);
  --mat-select-invalid-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(215, 86, 30, 0.87);
  --mat-select-invalid-arrow-color: rgba(215, 86, 30, 0.87);
}
.theme-level-up .dark-theme {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-autocomplete-background-color: #424242;
}
.theme-level-up .dark-theme {
  --mdc-dialog-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}
.theme-level-up .dark-theme {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-level-up .dark-theme .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-disabled-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-flat-disabled-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #7b42f5;
  --mdc-chip-elevated-selected-container-color: #7b42f5;
  --mdc-chip-elevated-disabled-container-color: #7b42f5;
  --mdc-chip-flat-disabled-selected-container-color: #7b42f5;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #10a5ce;
  --mdc-chip-elevated-selected-container-color: #10a5ce;
  --mdc-chip-elevated-disabled-container-color: #10a5ce;
  --mdc-chip-flat-disabled-selected-container-color: #10a5ce;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #d7561e;
  --mdc-chip-elevated-selected-container-color: #d7561e;
  --mdc-chip-elevated-disabled-container-color: #d7561e;
  --mdc-chip-flat-disabled-selected-container-color: #d7561e;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-level-up .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-level-up .dark-theme {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
.theme-level-up .dark-theme .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.theme-level-up .dark-theme {
  --mdc-switch-selected-focus-state-layer-color: #a37bf8;
  --mdc-switch-selected-handle-color: #a37bf8;
  --mdc-switch-selected-hover-state-layer-color: #a37bf8;
  --mdc-switch-selected-pressed-state-layer-color: #a37bf8;
  --mdc-switch-selected-focus-handle-color: #bda1fa;
  --mdc-switch-selected-hover-handle-color: #bda1fa;
  --mdc-switch-selected-pressed-handle-color: #bda1fa;
  --mdc-switch-selected-focus-track-color: #733cf4;
  --mdc-switch-selected-hover-track-color: #733cf4;
  --mdc-switch-selected-pressed-track-color: #733cf4;
  --mdc-switch-selected-track-color: #733cf4;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #58c0dd;
  --mdc-switch-selected-handle-color: #58c0dd;
  --mdc-switch-selected-hover-state-layer-color: #58c0dd;
  --mdc-switch-selected-pressed-state-layer-color: #58c0dd;
  --mdc-switch-selected-focus-handle-color: #88d2e7;
  --mdc-switch-selected-hover-handle-color: #88d2e7;
  --mdc-switch-selected-pressed-handle-color: #88d2e7;
  --mdc-switch-selected-focus-track-color: #0e9dc9;
  --mdc-switch-selected-hover-track-color: #0e9dc9;
  --mdc-switch-selected-pressed-track-color: #0e9dc9;
  --mdc-switch-selected-track-color: #0e9dc9;
}
.theme-level-up .dark-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e38962;
  --mdc-switch-selected-handle-color: #e38962;
  --mdc-switch-selected-hover-state-layer-color: #e38962;
  --mdc-switch-selected-pressed-state-layer-color: #e38962;
  --mdc-switch-selected-focus-handle-color: #ebab8f;
  --mdc-switch-selected-hover-handle-color: #ebab8f;
  --mdc-switch-selected-pressed-handle-color: #ebab8f;
  --mdc-switch-selected-focus-track-color: #d34f1a;
  --mdc-switch-selected-hover-track-color: #d34f1a;
  --mdc-switch-selected-pressed-track-color: #d34f1a;
  --mdc-switch-selected-track-color: #d34f1a;
}
.theme-level-up .dark-theme {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.theme-level-up .dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b42f5;
  --mdc-radio-selected-hover-icon-color: #7b42f5;
  --mdc-radio-selected-icon-color: #7b42f5;
  --mdc-radio-selected-pressed-icon-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #7b42f5;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #10a5ce;
  --mdc-radio-selected-hover-icon-color: #10a5ce;
  --mdc-radio-selected-icon-color: #10a5ce;
  --mdc-radio-selected-pressed-icon-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #10a5ce;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #d7561e;
  --mdc-radio-selected-hover-icon-color: #d7561e;
  --mdc-radio-selected-icon-color: #d7561e;
  --mdc-radio-selected-pressed-icon-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #d7561e;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.theme-level-up .dark-theme {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}
.theme-level-up .dark-theme {
  --mdc-slider-handle-color: #7b42f5;
  --mdc-slider-focus-handle-color: #7b42f5;
  --mdc-slider-hover-handle-color: #7b42f5;
  --mdc-slider-active-track-color: #7b42f5;
  --mdc-slider-inactive-track-color: #7b42f5;
  --mdc-slider-with-tick-marks-inactive-container-color: #7b42f5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
}
.theme-level-up .dark-theme {
  --mat-slider-ripple-color: #7b42f5;
  --mat-slider-hover-state-layer-color: rgba(123, 66, 245, 0.05);
  --mat-slider-focus-state-layer-color: rgba(123, 66, 245, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
}
.theme-level-up .dark-theme .mat-accent {
  --mdc-slider-handle-color: #10a5ce;
  --mdc-slider-focus-handle-color: #10a5ce;
  --mdc-slider-hover-handle-color: #10a5ce;
  --mdc-slider-active-track-color: #10a5ce;
  --mdc-slider-inactive-track-color: #10a5ce;
  --mdc-slider-with-tick-marks-inactive-container-color: #10a5ce;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-accent {
  --mat-slider-ripple-color: #10a5ce;
  --mat-slider-hover-state-layer-color: rgba(16, 165, 206, 0.05);
  --mat-slider-focus-state-layer-color: rgba(16, 165, 206, 0.2);
}
.theme-level-up .dark-theme .mat-warn {
  --mdc-slider-handle-color: #d7561e;
  --mdc-slider-focus-handle-color: #d7561e;
  --mdc-slider-hover-handle-color: #d7561e;
  --mdc-slider-active-track-color: #d7561e;
  --mdc-slider-inactive-track-color: #d7561e;
  --mdc-slider-with-tick-marks-inactive-container-color: #d7561e;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-level-up .dark-theme .mat-warn {
  --mat-slider-ripple-color: #d7561e;
  --mat-slider-hover-state-layer-color: rgba(215, 86, 30, 0.05);
  --mat-slider-focus-state-layer-color: rgba(215, 86, 30, 0.2);
}
.theme-level-up .dark-theme {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
.theme-level-up .dark-theme {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}
.theme-level-up .dark-theme {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme .mdc-list-item__start,
.theme-level-up .dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b42f5;
  --mdc-radio-selected-hover-icon-color: #7b42f5;
  --mdc-radio-selected-icon-color: #7b42f5;
  --mdc-radio-selected-pressed-icon-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-accent .mdc-list-item__start,
.theme-level-up .dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #10a5ce;
  --mdc-radio-selected-hover-icon-color: #10a5ce;
  --mdc-radio-selected-icon-color: #10a5ce;
  --mdc-radio-selected-pressed-icon-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-warn .mdc-list-item__start,
.theme-level-up .dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #d7561e;
  --mdc-radio-selected-hover-icon-color: #d7561e;
  --mdc-radio-selected-icon-color: #d7561e;
  --mdc-radio-selected-pressed-icon-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #7b42f5;
  --mdc-checkbox-selected-hover-icon-color: #7b42f5;
  --mdc-checkbox-selected-icon-color: #7b42f5;
  --mdc-checkbox-selected-pressed-icon-color: #7b42f5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-hover-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-pressed-state-layer-color: #7b42f5;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-level-up .dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #10a5ce;
  --mdc-checkbox-selected-hover-icon-color: #10a5ce;
  --mdc-checkbox-selected-icon-color: #10a5ce;
  --mdc-checkbox-selected-pressed-icon-color: #10a5ce;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-hover-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-pressed-state-layer-color: #10a5ce;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-level-up .dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d7561e;
  --mdc-checkbox-selected-hover-icon-color: #d7561e;
  --mdc-checkbox-selected-icon-color: #d7561e;
  --mdc-checkbox-selected-pressed-icon-color: #d7561e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d7561e;
  --mdc-checkbox-selected-hover-state-layer-color: #d7561e;
  --mdc-checkbox-selected-pressed-state-layer-color: #d7561e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-level-up .dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-level-up .dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.theme-level-up .dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-level-up .dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-level-up .dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-level-up .dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-level-up .dark-theme {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.theme-level-up .dark-theme {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
.theme-level-up .dark-theme {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.theme-level-up .dark-theme .mat-mdc-tab-group,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-tab-group,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #7b42f5;
  --mat-tab-header-active-ripple-color: #7b42f5;
  --mat-tab-header-inactive-ripple-color: #7b42f5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #7b42f5;
  --mat-tab-header-active-hover-label-text-color: #7b42f5;
  --mat-tab-header-active-focus-indicator-color: #7b42f5;
  --mat-tab-header-active-hover-indicator-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-accent,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-accent,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #10a5ce;
  --mat-tab-header-active-ripple-color: #10a5ce;
  --mat-tab-header-inactive-ripple-color: #10a5ce;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #10a5ce;
  --mat-tab-header-active-hover-label-text-color: #10a5ce;
  --mat-tab-header-active-focus-indicator-color: #10a5ce;
  --mat-tab-header-active-hover-indicator-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-warn,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-warn,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #d7561e;
  --mat-tab-header-active-ripple-color: #d7561e;
  --mat-tab-header-inactive-ripple-color: #d7561e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d7561e;
  --mat-tab-header-active-hover-label-text-color: #d7561e;
  --mat-tab-header-active-focus-indicator-color: #d7561e;
  --mat-tab-header-active-hover-indicator-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-background-primary,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #7b42f5;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-background-accent,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #10a5ce;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-mdc-tab-group.mat-background-warn,
.theme-level-up .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #d7561e;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-level-up .dark-theme {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
.theme-level-up .dark-theme {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #10a5ce;
  --mdc-checkbox-selected-hover-icon-color: #10a5ce;
  --mdc-checkbox-selected-icon-color: #10a5ce;
  --mdc-checkbox-selected-pressed-icon-color: #10a5ce;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-hover-state-layer-color: #10a5ce;
  --mdc-checkbox-selected-pressed-state-layer-color: #10a5ce;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-level-up .dark-theme {
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-checkbox-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #7b42f5;
  --mdc-checkbox-selected-hover-icon-color: #7b42f5;
  --mdc-checkbox-selected-icon-color: #7b42f5;
  --mdc-checkbox-selected-pressed-icon-color: #7b42f5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-hover-state-layer-color: #7b42f5;
  --mdc-checkbox-selected-pressed-state-layer-color: #7b42f5;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-level-up .dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d7561e;
  --mdc-checkbox-selected-hover-icon-color: #d7561e;
  --mdc-checkbox-selected-icon-color: #d7561e;
  --mdc-checkbox-selected-pressed-icon-color: #d7561e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d7561e;
  --mdc-checkbox-selected-hover-state-layer-color: #d7561e;
  --mdc-checkbox-selected-pressed-state-layer-color: #d7561e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-level-up .dark-theme {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
.theme-level-up .dark-theme {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
.theme-level-up .dark-theme {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
.theme-level-up .dark-theme {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
.theme-level-up .dark-theme {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
.theme-level-up .dark-theme {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}
.theme-level-up .dark-theme {
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme {
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme {
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme {
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #7b42f5;
  --mat-text-button-ripple-color: rgba(123, 66, 245, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #10a5ce;
  --mat-text-button-ripple-color: rgba(16, 165, 206, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #d7561e;
  --mat-text-button-ripple-color: rgba(215, 86, 30, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #7b42f5;
  --mdc-filled-button-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #10a5ce;
  --mdc-filled-button-label-text-color: black;
}
.theme-level-up .dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #d7561e;
  --mdc-filled-button-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #7b42f5;
  --mdc-protected-button-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #10a5ce;
  --mdc-protected-button-label-text-color: black;
}
.theme-level-up .dark-theme .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #d7561e;
  --mdc-protected-button-label-text-color: white;
}
.theme-level-up .dark-theme .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #7b42f5;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #7b42f5;
  --mat-outlined-button-ripple-color: rgba(123, 66, 245, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #10a5ce;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #10a5ce;
  --mat-outlined-button-ripple-color: rgba(16, 165, 206, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #d7561e;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #d7561e;
  --mat-outlined-button-ripple-color: rgba(215, 86, 30, 0.1);
}
.theme-level-up .dark-theme {
  --mdc-icon-button-icon-size: 24px;
}
.theme-level-up .dark-theme {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme {
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
}
.theme-level-up .dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #7b42f5;
  --mat-icon-button-ripple-color: rgba(123, 66, 245, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #10a5ce;
  --mat-icon-button-ripple-color: rgba(16, 165, 206, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #d7561e;
  --mat-icon-button-ripple-color: rgba(215, 86, 30, 0.1);
}
.theme-level-up .dark-theme {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-fab-container-color: #424242;
}
.theme-level-up .dark-theme {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme {
  --mdc-fab-small-container-color: #424242;
}
.theme-level-up .dark-theme {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-level-up .dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #d7561e;
}
.theme-level-up .dark-theme .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-level-up .dark-theme {
  --mdc-snackbar-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mdc-snackbar-container-color: rgb(217.2, 217.2, 217.2);
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
}
.theme-level-up .dark-theme {
  --mat-snack-bar-button-color: #7b42f5;
}
.theme-level-up .dark-theme {
  --mat-table-row-item-outline-width: 1px;
}
.theme-level-up .dark-theme {
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}
.theme-level-up .dark-theme {
  --mdc-circular-progress-active-indicator-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-accent {
  --mdc-circular-progress-active-indicator-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-warn {
  --mdc-circular-progress-active-indicator-color: #d7561e;
}
.theme-level-up .dark-theme {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}
.theme-level-up .dark-theme {
  --mat-badge-background-color: #7b42f5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: rgb(110.1, 110.1, 110.1);
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}
.theme-level-up .dark-theme .mat-badge-accent {
  --mat-badge-background-color: #10a5ce;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-badge-warn {
  --mat-badge-background-color: #d7561e;
  --mat-badge-text-color: white;
}
.theme-level-up .dark-theme {
  --mat-bottom-sheet-container-shape: 4px;
}
.theme-level-up .dark-theme {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
}
.theme-level-up .dark-theme {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
.theme-level-up .dark-theme {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}
.theme-level-up .dark-theme {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
}
.theme-level-up .dark-theme {
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: rgb(88.68, 88.68, 88.68);
}
.theme-level-up .dark-theme {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #7b42f5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(123, 66, 245, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(123, 66, 245, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(123, 66, 245, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #7b42f5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(123, 66, 245, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
}
.theme-level-up .dark-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #10a5ce;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(16, 165, 206, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(16, 165, 206, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(16, 165, 206, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(16, 165, 206, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.theme-level-up .dark-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #d7561e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(215, 86, 30, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(215, 86, 30, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(215, 86, 30, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(215, 86, 30, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.theme-level-up .dark-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #d7561e;
}
.theme-level-up .dark-theme {
  --mat-divider-width: 1px;
}
.theme-level-up .dark-theme {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}
.theme-level-up .dark-theme {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}
.theme-level-up .dark-theme {
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}
.theme-level-up .dark-theme {
  --mat-icon-color: inherit;
}
.theme-level-up .dark-theme .mat-icon.mat-primary {
  --mat-icon-color: #7b42f5;
}
.theme-level-up .dark-theme .mat-icon.mat-accent {
  --mat-icon-color: #10a5ce;
}
.theme-level-up .dark-theme .mat-icon.mat-warn {
  --mat-icon-color: #d7561e;
}
.theme-level-up .dark-theme {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}
.theme-level-up .dark-theme {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
}
.theme-level-up .dark-theme {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #7b42f5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #7b42f5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #7b42f5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #d7561e;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #d7561e;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.theme-level-up .dark-theme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #10a5ce;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #10a5ce;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #10a5ce;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #d7561e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #d7561e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #d7561e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-level-up .dark-theme {
  --mat-sort-arrow-color: rgb(198.3, 198.3, 198.3);
}
.theme-level-up .dark-theme {
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
}
.theme-level-up .dark-theme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #7b42f5;
  --mat-toolbar-container-text-color: white;
}
.theme-level-up .dark-theme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #10a5ce;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.theme-level-up .dark-theme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #d7561e;
  --mat-toolbar-container-text-color: white;
}
.theme-level-up .dark-theme {
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}
.theme-level-up .dark-theme {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-level-up .dark-theme {
  --mat-timepicker-container-background-color: #424242;
}
.theme-level-up pt-breadcrumbs a {
  color: #7b42f5;
}
.theme-level-up pt-breadcrumbs a:hover {
  color: #b5a3ff;
}
.theme-level-up pt-breadcrumbs .breadcrumbs-wrapper {
  padding: 0 0 8px;
}
.theme-level-up .mat-caption {
  opacity: 0.75;
}
.theme-level-up mat-chip-listbox.compact .mat-mdc-chip-listbox-wrapper {
  margin: -4px 0;
}
.theme-level-up mat-chip-listbox.compact .mat-mdc-chip-listbox-wrapper .mat-standard-chip {
  margin: 1px;
}
.theme-level-up mat-list.flush-list .mat-list-item-content {
  padding: 0 !important;
}
.theme-level-up mat-list.with-dividers .mat-mdc-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.theme-level-up button.square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
.theme-level-up .mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: $size !important;
  --mdc-icon-button-icon-size: $size !important;
  line-height: 1px !important;
  padding: 8px !important;
}
.theme-level-up .mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 32px;
  height: 32px;
}
.theme-level-up .mat-mdc-icon-button .mat-icon {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  line-height: 1;
  line-height: 24px;
}
.theme-level-up .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px !important;
}
.theme-level-up .toolbar-dialog mat-dialog-container {
  padding: 0;
}
.theme-level-up .mat-mdc-tab,
.theme-level-up .mat-mdc-tab-link {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.theme-level-up .mat-mdc-tab .mdc-tab__ripple,
.theme-level-up .mat-mdc-tab-link .mdc-tab__ripple {
  display: none;
}
.theme-level-up .mat-mdc-tab:hover,
.theme-level-up .mat-mdc-tab-link:hover {
  background: white !important;
}
.theme-level-up .mat-mdc-tab:hover .mdc-tab__text-label,
.theme-level-up .mat-mdc-tab-link:hover .mdc-tab__text-label {
  background: #f2eaff !important;
}
.theme-level-up .mat-mdc-tab .mdc-tab__text-label,
.theme-level-up .mat-mdc-tab-link .mdc-tab__text-label {
  padding: 8px 13px !important;
  border-radius: 8px !important;
}
.theme-level-up .mat-mdc-tab .mdc-tab-indicator,
.theme-level-up .mat-mdc-tab-link .mdc-tab-indicator {
  display: none;
}
.theme-level-up .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.theme-level-up .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  background: #f2eaff !important;
  padding: 8px 12px;
  border-radius: 8px;
  font-weight: bold !important;
}
.theme-level-up a {
  text-decoration: none;
}
.theme-level-up a:link {
  color: #7b42f5;
}
.theme-level-up a:visited {
  color: #6833f2;
}
.theme-level-up a:hover {
  color: #8f5ef7;
}
.theme-level-up a:active {
  color: #733cf4;
}
.theme-level-up p {
  margin: 12px 0;
}
.theme-level-up .toolbar-dialog mat-dialog-container {
  padding: 0;
}
.theme-level-up .mat-sidenav-container {
  height: 100%;
  background-color: transparent;
}
.theme-level-up .mat-tooltip {
  font-size: 12px !important;
}
.theme-level-up .multi-line-tooltip {
  white-space: pre-line;
}
.theme-level-up .mat-progress-bar-buffer {
  background-color: #cccccc;
}
.theme-level-up .mat-progress-bar-fill::after {
  background-color: #3ec26b;
}
.theme-level-up .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-level-up .mat-mdc-raised-button.mat-mdc-button-base,
.theme-level-up .mat-mdc-outlined-button.mat-mdc-button-base,
.theme-level-up .mat-mdc-fab.mat-mdc-button-base,
.theme-level-up .mat-mdc-mini-fab.mat-mdc-button-base {
  min-height: 36px;
  height: inherit;
}
.theme-level-up .mat-mdc-unelevated-button.dense,
.theme-level-up .mat-mdc-raised-button.dense,
.theme-level-up .mat-mdc-outlined-button.dense,
.theme-level-up .mat-mdc-fab.dense,
.theme-level-up .mat-mdc-mini-fab.dense {
  font-size: 12px;
  line-height: 24px;
  padding: 0 8px;
  min-height: 30px;
}
.theme-level-up .mat-mdc-unelevated-button.mat-accent,
.theme-level-up .mat-mdc-raised-button.mat-accent,
.theme-level-up .mat-mdc-fab.mat-accent,
.theme-level-up .mat-mdc-mini-fab.mat-accent {
  color: #fff;
}
.theme-level-up .mdc-dialog__title + .mdc-dialog__content,
.theme-level-up .mdc-dialog__header + .mdc-dialog__content {
  padding-top: 20px;
}
.theme-level-up .mat-mdc-dialog-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 25px;
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-form-field-wrapper {
  height: 36px;
  padding-bottom: 0;
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-form-field-wrapper .mat-mdc-form-field-underline {
  bottom: 0;
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-form-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 7px;
  padding-bottom: 0;
  min-height: 36px;
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-form-field-infix {
  border-top: 0;
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-21.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 7px;
  padding-bottom: 0;
  min-height: 36px;
}
.theme-level-up .mat-mdc-form-field.compact .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  font-size: 12px;
  top: 15px;
}
.theme-level-up .mat-mdc-form-field-hint-wrapper {
  color: rgba(0, 0, 0, 0.54);
  font-size: 10.5px;
}
.theme-level-up .mdc-notched-outline > * {
  border-color: #ddd !important;
}
.theme-level-up .mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end,
.theme-level-up .mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: flex;
  align-self: center;
}
.theme-level-up .mat-mdc-tab-nav-bar,
.theme-level-up .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme-level-up ngx-auth-firebaseui.flat .mat-card {
  box-shadow: none;
}
.theme-level-up ngx-auth-firebaseui.flat .mat-divider {
  display: none;
}
.theme-level-up .cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.theme-level-up .cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
.theme-level-up .cdk-drag-placeholder {
  opacity: 0.1;
  background: #ccc;
}
.theme-level-up .cdk-drag-handle {
  cursor: pointer;
}
.theme-level-up .cdk-drag-preview {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 0 16px;
  background: #fff;
}
.theme-level-up .elevated .pt-connected-dialog-container {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-level-up .no-padding .pt-connected-dialog-container {
  padding: 0;
}
.theme-level-up .connected-dialog-attached-bottom .pt-connected-dialog-container {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-level-up pt-menu-bar .mat-mdc-button,
.theme-level-up pt-menu-bar .mat-mdc-icon-button,
.theme-level-up pt-menu-bar .mat-mdc-outlined-button,
.theme-level-up pt-menu-bar .mat-mdc-unelevated-button {
  padding: 0 8px;
  min-width: 44px;
}
.theme-level-up pt-menu-bar .mat-mdc-button .mat-icon,
.theme-level-up pt-menu-bar .mat-mdc-icon-button .mat-icon,
.theme-level-up pt-menu-bar .mat-mdc-outlined-button .mat-icon,
.theme-level-up pt-menu-bar .mat-mdc-unelevated-button .mat-icon {
  width: 20px !important;
  height: 20px !important;
  font-size: 20px !important;
  line-height: 1;
  margin-right: 0;
}
.theme-level-up .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-level-up .mat-mdc-raised-button.mat-mdc-button-base,
.theme-level-up .mat-mdc-outlined-button.mat-mdc-button-base,
.theme-level-up .mat-mdc-fab.mat-mdc-button-base,
.theme-level-up .mat-mdc-mini-fab.mat-mdc-button-base {
  min-height: 36px;
  height: inherit;
}
.theme-level-up .mat-mdc-unelevated-button.dense,
.theme-level-up .mat-mdc-raised-button.dense,
.theme-level-up .mat-mdc-outlined-button.dense,
.theme-level-up .mat-mdc-fab.dense,
.theme-level-up .mat-mdc-mini-fab.dense {
  font-size: 12px;
  line-height: 24px;
  padding: 0 8px;
}
@media only screen and (max-width: 1023px) {
  .theme-level-up pt-page-footer pt-buttons-container {
    max-width: 100%;
    margin: 0 70px 0 -8px;
  }
}
.theme-level-up .warn {
  color: #d7561e;
}
.theme-level-up .intercom-app iframe.intercom-launcher-frame,
.theme-level-up div.intercom-lightweight-app {
  z-index: 999;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

@media print {
  html,
  body {
    height: auto !important;
    min-height: auto !important;
  }
  html:last-child,
  body:last-child {
    break-after: auto;
  }
}
@use 'sass:map';
@use '@angular/material' as mat;
@use './variables' as variables;
@use 'scss-shared' as shared;
@use './colours' as colours;

@mixin breadcrumbs-theme($theme) {
  pt-breadcrumbs {
    $primary: map.get($theme, primary);
    a {
      color: mat.m2-get-color-from-palette($primary);
      &:hover {
        color: mat.m2-get-color-from-palette($primary, A700);
      }
    }
    .breadcrumbs-wrapper {
      padding: 0 0 8px;
    }
  }
}

@mixin page-wrapper {
  max-width: variables.$page-wrap;
  margin: 0 auto;
}

@mixin small-mat-chip {
  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-label-text-size: 10px;
    --mdc-chip-container-height: 22px;
  }
}

@mixin sticky-nav() {
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: shared.$mat-margin;
  z-index: 10;
  background: #fff !important;
  border-radius: shared.$half-margin shared.$half-margin 0 0;
}

@mixin placeholder-light {
  &::placeholder {
    color: colours.$light-grey;
  }
}

@mixin placeholder-dark {
  &::placeholder {
    color: colours.$dark-grey;
  }
}

@mixin custom-input($padding: 2px 6px) {
  @include shared.border-radius(4px);
  @include placeholder-dark;
  border: 1px solid colours.$light-grey;
  padding: $padding;

  &:focus {
    outline-color: colours.$light-grey;
  }
}

.custom-input-default {
  @include custom-input;
}

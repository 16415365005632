@mixin ngx-auth-firebaseui-extended() {
  ngx-auth-firebaseui {
    &.flat {
      .mat-card {
        box-shadow: none;
      }
      .mat-divider {
        display: none;
      }
    }
  }
}

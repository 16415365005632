@forward './partials/border';
@forward './partials/connected-dialog';
@forward './partials/editor';
@forward './partials/flex';
@forward './partials/interactjs';
@forward './partials/layout';
@forward './partials/mixins';
@forward './partials/custom-scrollbar';

@use './partials/layout' as layout;
@use './partials/mixins' as mixins;

@mixin helper-classes() {
  .layout-padding {
    @include layout.layout-padding;
  }

  .layout-margin {
    @include layout.layout-margin;
  }

  .placeholder {
    @include mixins.placeholder;
  }

  .tooltip-multiline {
    white-space: pre;
  }
}

@mixin root-body-fix {
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  @media print {
    html,
    body {
      height: auto !important;
      min-height: auto !important;

      &:last-child {
        break-after: auto;
      }
    }
  }
}

/**
 * Used for padding and margins
 * @nuclide mat-margin
 * @section Layout > Margin & Padding
 */
$mat-margin: 16px;
$half-margin: 8px;

/**
 * Apply a consistent padding to this element
 * @mixin
 * @section Layout Mixins
 * @example
 *  @include layout-padding;
 */
@mixin layout-padding {
  padding: $mat-margin;
}

/**
 * Apply a consistent margin to this element
 * @mixin
 * @section Layout Mixins
 * @example
 *  @include layout-margin;
 */
@mixin layout-margin {
  margin: $mat-margin;
}

@use '@angular/cdk' as cdk;
@use '@angular/material' as mat;

$pr-dialog-padding: 24px !default;
$pr-dialog-border-radius: 4px !default;
$pr-dialog-max-height: 65vh !default;
$pr-dialog-button-margin: 8px !default;

.pt-connected-dialog-container {
  display: block;
  border-radius: $pr-dialog-border-radius;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  background: white;

  @include cdk.high-contrast() {
    outline: solid 1px;
  }

  @include mat.elevation(24);
}

.pt-dialog-content {
  display: block;
  margin: 0;
  padding: 0 $pr-dialog-padding;
  max-height: $pr-dialog-max-height;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.pt-dialog-title {
  display: block;
}

.pt-dialog-actions {
  padding: 8px $pr-dialog-padding;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  justify-content: flex-end;

  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }

  .mat-mdc-button + .mat-button,
  .mat-mdc-raised-button + .mat-mdc-raised-button,
  .mat-button + .mat-mdc-raised-button,
  .mat-mdc-raised-button + .mat-button {
    margin-left: $pr-dialog-button-margin;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: $pr-dialog-button-margin;
    }
  }
}

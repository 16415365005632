@use '@angular/material' as mat;
@use 'scss-shared' as shared;

@mixin drag-drop {
  .cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  .cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  }
  .cdk-drag-placeholder {
    opacity: 0.1;
    background: #ccc;
  }
  .cdk-drag-handle {
    cursor: pointer;
  }
}

@mixin cdk-drag-preview {
  .cdk-drag-preview {
    @include shared.rounded-outline;
    @include mat.elevation(1);
    box-sizing: border-box;
    padding: 0 shared.$mat-margin;
    background: #fff;
  }
}

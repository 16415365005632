@use 'sass:map';
@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './mixins.scss' as mixins;
@use './material-theme' as theme;

@mixin misc {
  .elevated .pt-connected-dialog-container {
    @include mat.elevation(5);
  }

  .no-padding .pt-connected-dialog-container {
    padding: 0;
  }

  .connected-dialog-attached-bottom .pt-connected-dialog-container {
    @include mat.elevation(5);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  pt-menu-bar {
    .mat-mdc-button,
    .mat-mdc-icon-button,
    .mat-mdc-outlined-button,
    .mat-mdc-unelevated-button {
      padding: 0 8px;
      min-width: 44px;

      .mat-icon {
        @include shared.mat-icon-size(20px);
        margin-right: 0;
      }
    }
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-mdc-button-base {
      min-height: 36px;
      height: inherit;
    }
    &.dense {
      font-size: 12px;
      line-height: 24px;
      padding: 0 8px;
    }
  }

  pt-page-footer pt-buttons-container {
    @media only screen and (max-width: 1023px) {
      max-width: 100%;
      margin: 0 70px 0 -8px;
    }
  }

  .warn {
    color: map.get(theme.$warn, 500);
  }
}

/**
 * Apply a consistent border radius to this element
 * @mixin
 * @section Layout Mixins
 * @example
 *  @include border-radius(10px);
 */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin rounded-outline($radius: 8px) {
  @include border-radius($radius);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

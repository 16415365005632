// Theme
$outer-space: #474747;
$flame: #d7561e;
$light-slate-grey: #6e8898;
$wild-blue-yonder: #9fb1bc;
$light-grey: #d3d0cb;
$lighter-grey: #f5f5f5;
$dark-grey: #5f6368;
$light-blue: #10a5ce;

// Progress Colours
$incomplete: #8f8f8f;
$request: #f7b500;
$complete: #3ec26b;
$certified: #3897f0;

// Logo
$logo-primary: $flame;
$logo-accent: $outer-space;

// Material Core Colours
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

@mixin placeholder {
  background-color: rgba(0, 0, 0, 0.1);
}

/**
 * Apply a grayscale to this element
 * @mixin
 * @example
 *  @extend grayscale(100%);
 */
@mixin grayscale($percent) {
  -webkit-filter: grayscale($percent); /* Safari 6.0 - 9.0 */
  filter: grayscale($percent);
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin mat-icon-size($size) {
  width: $size !important;
  height: $size !important;
  font-size: $size !important;
  line-height: 1;
}

@mixin mat-icon-button-size($size: 40px, $padding: 0) {
  --mdc-icon-button-state-layer-size: $size !important;
  --mdc-icon-button-icon-size: $size !important;
  line-height: 1px !important;

  padding: $padding !important;

  .mat-mdc-button-touch-target {
    width: $size + 8;
    height: $size + 8;
  }

  .mat-icon {
    @include mat-icon-size($size);
    line-height: $size;
  }
}

@mixin mat-button-size($size: 14px) {
  --mdc-typography-button-font-size: $size !important;
  --mdc-icon-button-state-layer-size: $size !important;
  --mdc-icon-button-icon-size: $size !important;
  font-size: $size !important;

  .mat-icon {
    @include mat-icon-size($size + 4);
  }
}

@mixin shadow() {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

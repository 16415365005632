@media print {
  mat-sidenav,
  button,
  snack-bar-container {
    display: none !important;
  }

  mat-sidenav-content {
    margin-left: 0 !important;
  }

  .mat-drawer-container,
  .mat-drawer-content {
    overflow: visible !important;
  }
}
